export async function PromiseReplicate<T extends {}>(
  local: PouchDB.Database<T>,
  remote: PouchDB.Database<T>,
  updatingFn: Function
): Promise<PouchDB.Replication.ReplicationResultComplete<T>> {
  const [infoRemote, infoLocal] = await Promise.all([
    remote.info(),
    local.info(),
  ]);

  const initPercentage = (infoLocal.doc_count / infoRemote.doc_count) * 100;

  if (updatingFn) updatingFn(initPercentage);

  return new Promise<PouchDB.Replication.ReplicationResultComplete<T>>((resolve, reject) => {
    return local.replicate
      .from(remote)
      .on('change', (info) => {
        if (updatingFn) {
          const percent =
            (info.docs_written / infoRemote.doc_count) * 100;
          updatingFn(parseInt(String(percent), 10));
        }
      })
      .on('complete', (data) => {
        if (updatingFn) updatingFn(100);
        return resolve(data);
      })
      .on('error', (err) => {
        return reject(new Error('Problema replicación'));
      })
  });
};
