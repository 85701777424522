import { defineNuxtPlugin } from "#app";
import PouchDB from "pouchdb";
import type { IHorarios, IServicio, Tarjeta, IUserConf } from "@/interfaces";

declare module '#app' {
  interface NuxtApp {
    $db: {
      PouchDB: PouchDB.Static<{}>;
      Usuario: PouchDB.Database<{ _id: keyof IUserConf; value: any }>;
      Servicios: PouchDB.Database<IServicio>;
      ServiciosRemote: PouchDB.Database<IServicio>;
      Horarios: PouchDB.Database<IHorarios>;
      HorariosRemote: PouchDB.Database<IHorarios>;
      Tarjetas: PouchDB.Database<{ value: Tarjeta }>;
    };
  }
}

export default defineNuxtPlugin(async (nuxtApp) => {
  const BASE_URL = 'https://db.mibiotren.cl';

  const Usuario = new PouchDB<{ _id: keyof IUserConf; value: any }>('Usuario');
  const ServiciosRemote = new PouchDB<IServicio>(`${BASE_URL}/servicios`);
  const Servicios = new PouchDB<IServicio>('servicios');
  const HorariosRemote = new PouchDB<IHorarios>(`${BASE_URL}/horariosv4`);
  const Horarios = new PouchDB<IHorarios>('horariosv4');
  const Tarjetas = new PouchDB<{ value: Tarjeta }>('Tarjetas');

  const DB_API: typeof nuxtApp.$db = {
    PouchDB,
    Usuario,
    Servicios,
    ServiciosRemote,
    Horarios,
    HorariosRemote,
    Tarjetas,
  };

  return {
    provide: {
      BASE_URL,
      db: DB_API,
    }
  };
});
