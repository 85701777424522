import { defineNuxtPlugin } from "#app";
import Axios from 'axios';

export default defineNuxtPlugin(() => {
  const axios = Axios.create({
    timeout: 5e3,
  });

  return {
    provide: {
      axios,
    }
  };
});